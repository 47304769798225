// Model
import { model } from "../common/Model";

// Utils
import Utils from "../common/Utils";

class List {

  constructor() {
    let items = document.querySelectorAll(".type");
    let backgrounds = document.querySelectorAll(".background");
    let types = document.querySelectorAll(".inner");
    let typeBackgrounds = document.querySelectorAll(".type-background");
    let typeItems = document.querySelectorAll(".type-item");

    this.model = {
      projectItems: document.querySelectorAll(".items li"),
      projects: document.querySelectorAll(".items li.hide"),
      desktopImages: document.querySelectorAll(".desktop-image"),
      mobileImages: document.querySelectorAll(".mobile-image"),
      counter: 0,
      screen: { width: null, height: null },
      items,
      types,
      typeItems,
      typeBackgrounds,
      backgrounds,
      type: types[0].querySelector("h2"),
      name: items[0],
      intro: document.querySelectorAll('#intro-h2 span'),
      nameValue: document.querySelector('#name-value'),
      introTimeline: new TimelineLite(),
      scrollContainer: document.querySelector("#scroll-container"),
      words: ["writes", "programs", "codes", "scripts", "compiles", "collaborates on", "refactors", "reboots", "object-orients", "Babelizes", "Initializes", "animates", "Builds", "Constructs", "positions", "styles", "engineers", "deploys", "optimizes", "obfuscates", "minifies", "discusses", "gamifies"]
    }

    this.model.words = Utils.shuffle(this.model.words);

    this.onTick();
    this.model.items.forEach((item, index) => {
      let bounds = item.getBoundingClientRect();
      let position = Math.floor((this.model.screen.height * 0.5));
      let backgroundPosition = bounds.top * 0.1;
      let type = this.model.types[index];
      let typeBackground = this.model.typeBackgrounds[index];

      TweenMax.set(type, { y: position });

      if(typeBackground) {
        TweenMax.set(typeBackground, { y: backgroundPosition });
      }
    });

    this.events();
    this.setupIntro();
  }

  setupIntro() {
    let timeline = new TimelineLite({
      onComplete: () => {
        // TweenMax.delayedCall(1, this.changeName, null, this);

        this.loadImage(0);
      }
    });
    this.model.intro.forEach((intro, index) => {
      // let yPos = index % 2 === 0 ? -30 : 30;
      let yPos = { x: -30 };
      if(index === 1) {
        yPos = { y: -30 }
      } else if(index === 2) {
        yPos = { x: 30 }
      } else if(index === 3) {
        yPos = { x: 30 }
      }

      let overlap = "-=0";
      if(index > 0) {
        overlap = "-=0.4";
      }
      timeline.add(TweenMax.fromTo(intro, 0.5,
        yPos,
        {
          y: 0,
          x: 0,
          autoAlpha: 1,
          ease: Power3.easeOut
        })
      , overlap);
    });
  }

  loadImage(index) {
    let project = this.model.projects[index];
    let src = this.model.screen.width < 768 ? project.dataset.mobileImage : project.dataset.image;
    let image = new Image();
    image.onload = () => {
      // project.classList.add("active");
      this.model.mobileImages[index].src = project.dataset.mobileImage;
      this.model.desktopImages[index].src = project.dataset.image;

      if(index === 0) {
        this.model.projects.forEach(project => project.classList.remove("hide"));

        this.scrollIntro(index);
      } else if(index < this.model.projects.length - 1) {
        this.loadNext(index);
      }
    }

    if(src) {
      image.src = src;
    }
  }

  scrollIntro(index) {
    this.loadNext(index);

    // let position = this.model.screen.width < 768 ? 100 : 50;
    TweenMax.to(this.model.scrollContainer, 0.5,
      { scrollTo: { y: 50, autoKill: true },
      ease: Power3.easeInOut,
      onComplete: () => {
        this.changeName();
      },
      delay: 0.1
    });
  }

  loadNext(index) {
    let next = index + 1;
    this.loadImage(next);
  }

  changeName() {
    let color1 = Utils.getRandomColor();
    let color2 = Utils.getRandomColor();

    let tweens = [];

    tweens.push(
      TweenMax.to(this.model.name, 2, { color: color2, onComplete: this.changeName.bind(this), ease: Linear.easeNone })
    );

    tweens.push(
      TweenMax.to(this.model.type, 2, { backgroundColor: color1, ease: Linear.easeNone })
     );

    let localTimeline = new TimelineLite({
      tweens: tweens
    });

    this.model.nameValue.innerHTML = this.model.words[this.model.counter].toLowerCase();

    if(this.model.counter < this.model.words.length - 1) {
      this.model.counter++;
    } else {
      this.model.words = Utils.shuffle(this.model.words);
      this.model.counter = 0;
    }

    this.model.introTimeline.add(localTimeline);
  }

  /**
   * Setup events
   */
  events() {
    // Ticker
    TweenMax.ticker.addEventListener("tick", event => this.onTick());
    // setInterval(this.onTick.bind(this), 1);
  }

  /**
   * Ticker
   */
  onTick() {
    let winWidth = window.innerWidth;
    let winHeight = window.innerHeight;
    if(this.model.screen.width !== winWidth || this.model.screen.height !== winHeight) {
      this.model.screen = { width: winWidth, height: winHeight };
      this.resize();
    }

    let scroll = this.model.scrollContainer.scrollTop;
    let itemNumber = Math.ceil(scroll / this.model.screen.height);
    // if(itemNumber < 0) itemNumber = 0;
    // this.model.items.forEach((item, index) => {
    //   if(index === itemNumber - 1 || index === itemNumber || index === itemNumber + 1) {
        this.setNamePosition(itemNumber);
      // }
    // });

    if(!this.model.paused && scroll > this.model.screen.height * 0.5) {
      this.model.paused = true;
      this.model.introTimeline.pause();
    } else if(this.model.paused && scroll <= this.model.screen.height * 0.5) {
      this.model.paused = false;
      this.model.introTimeline.resume();
    }
  }

  resize() {
    this.model.projectItems.forEach(project => {
      TweenMax.set(project, { height: this.model.screen.height });
    });
  }

  setNamePosition(index) {
    let item = this.model.items[index];
    let type = this.model.types[index];
    let background = this.model.desktopImages[index - 1];

    let typeBackground = this.model.typeBackgrounds[index];
    let typeItem = this.model.typeItems[index - 1];

    let bounds = item.getBoundingClientRect();
    let position = ((-bounds.top * 0.75) + (this.model.screen.height * 0.5));
    let percent = (bounds.top / this.model.screen.height);
    let backPosition = (percent * 100);

    TweenMax.set(type, { y: position });

    if(background) {
      // TweenMax.set(background, { y: -backPosition });
    }

    if(typeBackground) {
      // let percent = (bounds.top / this.model.screen.height);
      let backgroundPositionX = (percent * 10);
      TweenMax.to(typeBackground, 0.05, { x: backgroundPositionX, y: backgroundPositionX, ease: Power4.easeOut });
    }

    if(typeItem) {
      // let percent = (bounds.top / this.model.screen.height);
      let backgroundPositionX = (percent * 10);
      TweenMax.to(typeItem, 0.05, { x: -backgroundPositionX, y: -backgroundPositionX, ease: Power4.easeOut });
    }

  }

}

export default List;