// Common
import Polyfills from "./common/Polyfills";

// Model
import { model } from "./common/Model";

// Common
import Common from "./common/Common";

// Utils
import Utils from "./common/Utils";

// pages
import List from "./pages/List";

window.JarretGabel = window.JarretGabel || {};

class App {

	constructor () {
    this.kickoff();
	}

  kickoff() {

    new Common();

    // Pages
    this.list = new List();
  }
}

window.JarretGabel.App = new App();